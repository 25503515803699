import React, { useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import {
  Dimmer,
  Icon,
  Loader,
  Menu,
  Message,
  Segment,
  Tab,
} from "semantic-ui-react";
import Documents from "./Documents";
import { getData, setData } from "../../../Utils";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";

const Home = () => {
  const [index, setIndex] = useState(getData("activeIndex") || 1);
  const { loading, data, error } = useQuery(CATEGORIES, {
    variables: { filtro: { estado: "ACTIVO", mostrarEstudiantes: 1 } },
  });
  const {
    loading: loadingProfile,
    error: errorProfile,
    data: dataProfile,
    refetch,
  } = useQuery(PROFILE);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [mutation] = useMutation(UPDATE_PROFILE);
  const handleActiveIndex = (e, { activeIndex }) => {
    setData("activeIndex", activeIndex);
    setIndex(activeIndex);
  };
  if (loading)
    return (
      <div>
        <Dimmer active inverted>
          <Loader size="large">Cargando</Loader>
        </Dimmer>
      </div>
    );

  const showAlert = () => {
    if (
      dataProfile &&
      dataProfile?.me?.fechaTerminosYCondicionesNuevos == null
    ) {
      Swal.fire({
        icon: "warning",
        html: "Hemos actualizado nuestra Política y tratamientos de datos. Al continuar utilizando nuestros servicios, acepta estos términos actualizados que puede consultar en nuestro <a href='https://multidiomas.edu.co/images/manual_politicas.pdf' target='_blank'>Manual de Políticas y Procedimientos  Habeas Data</a>",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#B0193F",
        allowEnterKey: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const input = {};
          input.fechaTerminosYCondicionesNuevos = new Date();
          await mutation({ variables: { input } });
          window.location.reload();
        }
      });
    }
  };

  showAlert();

  if (error || !data)
    return (
      <div style={{ margin: 30 }}>
        <Message>Ha ocurrido un error.</Message>
      </div>
    );

  const { multiCategorias } = data;

  if (!multiCategorias.length)
    return (
      <div style={{ margin: 30 }}>
        <Message>Actualmente no existe ninguna categoría</Message>
      </div>
    );

  const panes = multiCategorias.map((categorie, key) => ({
    menuItem: (
      <Menu.Item style={{ color: "#B0193F" }} key={key}>
        <h5>
          {" "}
          <Icon name="file alternate" />
          {categorie.nombre} <Icon name="angle right" />
        </h5>
      </Menu.Item>
    ),
    key,
    render: () => <Documents documents={categorie?.documentos} />,
  }));

  panes.unshift({
    menuItem: (
      <Menu.Item key="titulo" header>
        Categorias
      </Menu.Item>
    ),
    key: "titulo",
    render: () => <Documents documents={multiCategorias[0]?.documentos} />,
  });

  return (
    <Segment basic>
      <Tab
        activeIndex={index}
        onTabChange={handleActiveIndex}
        className="sidebarMulti"
        menu={{ fluid: true, stackable: true, vertical: !isMobile }}
        menuPosition="left"
        panes={panes}
      />
    </Segment>
  );
};

const CATEGORIES = gql`
  query categories($filtro: MultiCategoriaFiltro!) {
    multiCategorias(filtro: $filtro) {
      id
      nombre
      orden
      estado
      documentos(estado: ACTIVO) {
        id
        nombre
        cupoLimitado
        fechaCaducidad
        fechaInicio
        mensajeFechaInicio
        mensajeCaducidad
        tipoDocumento {
          id
          valorParametro
        }
      }
    }
  }
`;

const UPDATE_PROFILE = gql`
  mutation updateProfile($input: ActualizarMultiUsuarioInput!) {
    actualizarPerfil(input: $input) {
      id
    }
  }
`;

const PROFILE = gql`
  query profile {
    me {
      fechaTerminosYCondicionesNuevos
    }
  }
`;
export default Home;
