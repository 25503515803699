import React, { useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Progress, Segment, Checkbox, Message } from "semantic-ui-react";
import Origin from "./Origin";
import { getData, toBase64 } from "../../Utils";
import InputTypePassword from "../../Components/InputTypePassword";
import { gql, useMutation, useQuery } from "@apollo/client";
import Swal from "sweetalert2";
import LOGO_MULTI from "../../assets/logo-multi.png";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../Utils/Constants";
import Multiculturalities from "./Multiculturalities";
import Cities from "./Cities";
import InputSearch from "../../Components/InputSearch";
import CameraDialog from "../../Components/CameraDialog";
const options = [
  { text: "Masculino", value: "Masculino", key: "Masculino" },
  { text: "Femenino", value: "Femenino", key: "Femenino" },
  { text: "Otro", value: "Otro", key: "Otro" },
];

const passwordError = 'La contraseña debe tener mínimo entre 8 y 15 caracteres, una letra minúscula, una mayúscula, un número y un carácter especial.';
const TyC = () => (
  <span> Click para aceptar nuestras <a rel="noreferrer" style={{ color: '#B0193F' }} href="https://multidiomas.edu.co/images/manual_politicas.pdf" target="_blank"> Política de datos</a> y <a rel="noreferrer" style={{ color: '#B0193F' }} href="https://multidiomas.edu.co/media/archivos/Pol%C3%ADticas-para-p%C3%A1gina-web-Multidiomas-Ltda-versi%C3%B3n-02-2021.pdf" target="_blank">Política de cookies.</a></span>
);

const CreateUser = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [firstSurname, setFirstSurname] = useState("");
  const [secondSurname, setSecondSurname] = useState("");
  const [document, setDocument] = useState("");
  const [email, setEmail] = useState("");
  const [repeatEmail, setRepeatEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [origin, setOrigin] = useState("");
  const [repeatOrigin, setRepeatOrigin] = useState("");
  const [externalName, setExternalName] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [civilStatus, setCivilStatus] = useState("");
  const [address, setAddress] = useState("");
  const [stratum, setStratum] = useState("");
  const [healthRegimen, setHealthRegimen] = useState("");
  const [levelEducation, setLevelEducation] = useState("");
  const [occupation, setOccupation] = useState("");
  const [disability, setDisability] = useState("");
  const [multiculturalities, setMulticulturalities] = useState([]);
  const [cityDocument, setCityDocument] = useState("");
  const [cityUser, setCityUser] = useState("");
  const [dateBirth, setDateBirth] = useState("");
  const [dateDocument, setDateDocument] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [tyc, setTyc] = useState(false);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [readOnlyPhoto, setReadOnlyPhoto] = useState(false);
  const [photo, setPhoto] = useState({});

  let fields = [
    document,
    cityUser,
    address,
    dateDocument,
    dateBirth,
    documentType,
    cityDocument,
    gender,
    phone,
    firstName,
    firstSurname,
    origin,
    civilStatus,
    stratum,
    healthRegimen,
    levelEducation,
    occupation,
    disability,
  ];
  useEffect(() => {
    if (getData("tokenUserMulti")) history.replace("/dashboard/home");
  }, [history]);

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onCompleted = () => {
    Swal.fire('Su cuenta ha sido creada, hemos enviado un enlace de confirmación al correo que ha proporcionado.', '', 'success')
      .then(() => history.replace("/"))
  }

  const [mutation] = useMutation(CREATE_USER, { onCompleted, onError });
  const { loading: loadingData, error, data } = useQuery(FIELDS_USER);
  const handleMutation = async() => {
    setLoading(true);
    const input = {
      documento: document,
      primerNombre: firstName,
      primerApellido: firstSurname,
      email: email.trim(),
      tyc,
      password,
      direccion: address,
      genero: gender,
      valorProcedencia: origin,
      idTipoDocumento: documentType,
      idEstadoCivil: civilStatus,
      fechaNacimiento: dateBirth,
      idEstrato: stratum,
      idRegimenSalud: healthRegimen,
      idNivelFormacion: levelEducation,
      idOcupacion: occupation,
      idDiscapacidad: disability,
      fechaDocumentoExpedicion: dateDocument,
      idCiudad: cityUser,
      idCiudadDocumentoExpedicion: cityDocument,
      multicularidades: multiculturalities,
      telefono: phone
    }

    if (secondName.trim()) input.segundoNombre = secondName;
    if (secondSurname.trim()) input.segundoApellido = secondSurname;
    if (origin.trim() && origin.trim() === "3") {
      if (externalName.trim()) {
        input.valorProcedencia = origin;
        input.nombreExterno = externalName;
      }
    }

    input.file = await toBase64(photo);

    mutation({
      variables: { input }
    });
  };

  const countFields = () => {
    let count = 0;
    for (const key in fields) {
      const field = fields[key];
      if (field.trim()) count = count + 1;
    }
    /*add the total of extra validations and sum it with the length of the fields
     * totalValidation = 2
     * (example: ((countFields() / (fields.length + totalValidation)) * 100) )
    */
    if (EMAIL_REGEX.test(email) && email.trim() && email === repeatEmail) count = count + 1;
    if (PASSWORD_REGEX.test(password) && password === repeatPassword) count = count + 1;
    if (origin.trim() && (origin === repeatOrigin)) count = count + 1;
    if (multiculturalities.length > 0) count = count + 1;
    if (tyc) count = count + 1;
    return count;
  }

  const disabled = () => {
    if (!civilStatus.trim()) return true;
    if (!stratum.trim()) return true;
    if (!healthRegimen.trim()) return true;
    if (!levelEducation.trim()) return true;
    if (!occupation.trim()) return true;
    if (!disability.trim()) return true;
    if (!multiculturalities.length) return true;
    if (!password.trim()) return true;
    if (!PASSWORD_REGEX.test(password)) return true;
    if (password !== repeatPassword) return true;
    if (!origin.trim()) return true;
    if (origin !== repeatOrigin) return true;
    if (!tyc) return true;
    return false;
  }

  const disabledNext = () => {
    if (!dateBirth.trim()) return true;
    if (!phone.trim()) return true;
    if (!dateDocument.trim()) return true;
    if (!document.trim()) return true;
    if (!documentType.trim()) return true;
    if (!cityDocument.trim()) return true;
    if (!cityUser.trim()) return true;
    if (!address.trim()) return true;
    if (!firstName.trim()) return true;
    if (!firstSurname.trim()) return true;
    if (!gender.trim()) return true;
    if (!EMAIL_REGEX.test(email) || !email.trim()) return true;
    if (email !== repeatEmail) return true;
    if (!readOnlyPhoto) return true;

    return false;
  }

  const handleBackToLogin = () => {
    history.replace("/");
  }

  const validatePassword = () => {
    if (password.trim() && !PASSWORD_REGEX.test(password)) return passwordError;
    return null;
  }
/*
  const uploadPhoto =()=>{
    Swal.fire({
      icon: 'question',
      text: 'Cargar o tomar una foto?',
      input:'file',
      inputAttributes:{
        accept:".jpg, .png, .jpeg|image/*"
      },
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Cargar',
      denyButtonText: 'Tomar una foto',
    }).then((result)=>{
      if (result.isConfirmed) {
        if (result.value!==null) {
          setPhoto(result.value)
          setReadOnlyPhoto(true);
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'debe cargar la foto.'
          });
          return;
        }
      }else if (result.isDenied) {
        setOpen(true)
      }
    });
  }
*/
  return (
    <Grid className="login" style={{ height: '100vh', display: 'flex', justifyContent: 'center' }} verticalAlign='middle'>
      <Grid.Column style={{ display: 'flex', maxWidth: 800, justifyContent: 'center' }}>
        <Segment>
          <Icon onClick={handleBackToLogin} link size='big' name='arrow left' />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={LOGO_MULTI} alt="logo multidiomas" />
          </div>
          <br />
          <div style={{ textAlign: 'left' }}>
            <h3>Registrarse</h3>
          </div>
          <br /><br />
          <Progress percent={((countFields() / (fields.length + 5)) * 100).toFixed(0)} indicating attached='top' />
          <Progress percent={((countFields() / (fields.length + 5)) * 100).toFixed(0)} indicating attached='bottom' />
          <Form>
            {page === 1 && (
              <>
                <div style={{ textAlign: 'center', marginBottom: 30, flexDirection: 'column' }}>
                  <CameraDialog
                    open={open}
                    setOpen={setOpen}
                    setReadOnlyPhoto={setReadOnlyPhoto}
                    setPhoto={setPhoto}
                  />
                  <p>
                    <b>Foto de Usuario *</b>
                  </p>
                  <button onClick={/*uploadPhoto*/() => setOpen(true)} style={{ cursor: 'pointer', background: 'none', border: 0 }}>
                    {
                      readOnlyPhoto ?
                        <img src={typeof photo === 'string' ? photo : URL.createObjectURL(photo)} alt="foto" width="100" height="100" style={{ borderRadius: "50%" }} />
                        :
                        <Icon style={{ color: '#B0193F' }} name='user' size='huge' />
                    }
                  </button>
                </div>
                <Form.Group widths="equal">

                  <Form.Input
                    fluid
                    label="Documento *"
                    iconPosition='left'
                    icon={<Icon name='address card' style={{ color: '#B0193F' }} />}
                    value={document}
                    onChange={(e, { value }) => setDocument(value)}
                    placeholder="Documento" />
                  <InputSearch
                    loading={loadingData}
                    error={error}
                    value={documentType}
                    label="Tipo de documento *"
                    placeholder="Tipo de documento"
                    onChange={(value) => setDocumentType(value)}
                    data={data?.tipoDocumento} />
                </Form.Group>
                <Form.Group widths="equal">
                  <Cities
                    label="Lugar de nacimiento *"
                    value={cityUser}
                    onChange={value => setCityUser(value)}
                    placeholder="Lugar de nacimiento" />
                  <Cities
                    label="Lugar documento de expedición *"
                    value={cityDocument}
                    onChange={value => setCityDocument(value)}
                    placeholder="Lugar documento de expedición" />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Fecha de nacimiento *"
                    type="date"
                    value={dateBirth}
                    onChange={(e, { value }) => setDateBirth(value)}
                    max={new Date().toISOString().split("T")[0]}
                    placeholder="Fecha de nacimiento" />
                  <Form.Input
                    fluid
                    label="Fecha documento de expedición *"
                    type="date"
                    value={dateDocument}
                    onChange={(e, { value }) => setDateDocument(value)}
                    max={new Date().toISOString().split("T")[0]}
                    placeholder="Fecha documento de expedición" />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Primer nombre *"
                    value={firstName}
                    onChange={(e, { value }) => setFirstName(value)}
                    placeholder="Primer nombre" />
                  <Form.Input
                    fluid
                    label="Segundo nombre"
                    value={secondName}
                    onChange={(e, { value }) => setSecondName(value)}
                    placeholder="Segundo nombre" />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Primer apellido *"
                    value={firstSurname}
                    onChange={(e, { value }) => setFirstSurname(value)}
                    placeholder="Primer apellido" />
                  <Form.Input
                    fluid
                    label="Segundo apellido"
                    value={secondSurname}
                    onChange={(e, { value }) => setSecondSurname(value)}
                    placeholder="Segundo apellido" />
                </Form.Group>
                <Form.Group widths="equal">
                  <InputSearch
                    value={gender}
                    label="Género *"
                    placeholder="Género"
                    onChange={(value) => setGender(value)}
                    data={options} />
                  <Form.Input
                    fluid
                    label="Dirección *"
                    value={address}
                    onChange={(e, { value }) => setAddress(value)}
                    placeholder="Dirección" />
                  <Form.Input
                    fluid
                    type="number"
                    label="Celular *"
                    iconPosition='left'
                    icon={<Icon name='phone' rotated="clockwise" style={{ color: '#B0193F' }} />}
                    value={phone}
                    onChange={(e, { value }) => setPhone(value)}
                    placeholder="Celular" />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Correo electrónico *"
                    error={email.trim() ? !EMAIL_REGEX.test(email) : null}
                    iconPosition='left'
                    icon={<Icon name='mail' style={{ color: '#B0193F' }} />}
                    value={email}
                    onChange={(e, { value }) => setEmail(value)}
                    placeholder="Correo electrónico" />
                  <Form.Input
                    fluid
                    label="Confirmar correo electrónico *"
                    error={email !== repeatEmail}
                    iconPosition='left'
                    icon={<Icon name='mail' style={{ color: '#B0193F' }} />}
                    value={repeatEmail}
                    onChange={(e, { value }) => setRepeatEmail(value)}
                    placeholder="Confirmar correo electrónico" />
                </Form.Group>
                <div style={{ textAlign: 'center' }}>
                  {page <= 1 && <Form.Button
                    style={{ backgroundColor: '#B0193F', color: '#fff' }}
                    content="Siguiente"
                    disabled={disabledNext()}
                    onClick={() => setPage(page + 1)} />}
                </div>
              </>
            )}
            {page === 2 && (
              <>
                <Form.Group widths="equal">
                  <InputSearch
                    loading={loadingData}
                    error={error}
                    value={civilStatus}
                    label="Estado civil *"
                    placeholder="Estado civil"
                    onChange={(value) => setCivilStatus(value)}
                    data={data?.estadoCivil} />
                  <InputSearch
                    loading={loadingData}
                    error={error}
                    value={stratum}
                    label="Estrato *"
                    placeholder="Estrato"
                    onChange={(value) => setStratum(value)}
                    data={data?.estrato} />
                </Form.Group>
                <Form.Group widths="equal">
                  <InputSearch
                    loading={loadingData}
                    error={error}
                    value={healthRegimen}
                    label="Régimen de salud *"
                    placeholder="Régimen de salud"
                    onChange={(value) => setHealthRegimen(value)}
                    data={data?.regimenSalud} />
                  <InputSearch
                    loading={loadingData}
                    error={error}
                    value={levelEducation}
                    label="Nivel de formación *"
                    placeholder="Nivel de formación"
                    onChange={(value) => setLevelEducation(value)}
                    data={data?.nivelFormacion} />
                </Form.Group>
                <Form.Group widths="equal">
                  <InputSearch
                    loading={loadingData}
                    error={error}
                    value={occupation}
                    label="Ocupación *"
                    placeholder="Ocupación"
                    onChange={(value) => setOccupation(value)}
                    data={data?.ocupacion} />
                  <InputSearch
                    loading={loadingData}
                    error={error}
                    value={disability}
                    label="Discapacidad *"
                    placeholder="Discapacidad"
                    onChange={(value) => setDisability(value)}
                    data={data?.discapacidad} />
                </Form.Group>
                <Form.Group widths="equal">
                  <Origin
                    label="Procendencia"
                    value={origin}
                    error={origin !== repeatOrigin}
                    placeholder="Procendencia *"
                    onChange={(value) => setOrigin(value)} />
                  <Origin
                    label="Confirmar procendencia"
                    value={repeatOrigin}
                    error={origin !== repeatOrigin}
                    placeholder="Confirmar procendencia *"
                    onChange={(value) => setRepeatOrigin(value)} />
                  {origin === "3" && (
                    <Form.Input
                      fluid
                      label="Nombre entidad externa"
                      value={externalName}
                      onChange={(e, { value }) => setExternalName(value)}
                      placeholder="Nombre entidad externa" />
                  )}
                </Form.Group>
                <Form.Group widths="equal">
                  <Multiculturalities
                    value={multiculturalities}
                    onChange={(e, { value }) => setMulticulturalities(value)}
                    loading={loadingData}
                    error={error}
                    data={data} />
                </Form.Group>
                <Form.Group widths="equal">
                  <InputTypePassword
                    placeholder="Contraseña"
                    value={password}
                    label="Contraseña *"
                    error={validatePassword() || password !== repeatPassword}
                    onChange={(e, { value }) => setPassword(value)} />
                  <InputTypePassword
                    label="Repetir contraseña *"
                    placeholder="Repetir contraseña"
                    error={password !== repeatPassword}
                    value={repeatPassword}
                    onChange={(e, { value }) => setRepeatPassword(value)} />
                </Form.Group>
              </>
            )}
          </Form>
          {page === 2 && (
            <div style={{ textAlign: 'center' }}>
              <Message warning >
                <Checkbox checked={tyc} onChange={(e, { checked }) => setTyc(checked)} style={{ marginRight: 10 }} />
                <TyC />
              </Message>
              {page > 1 && <Button
                style={{ backgroundColor: '#B0193F', color: '#fff' }}
                content="Anterior"
                onClick={() => setPage(page - 1)} />}
              <Button
                icon="user plus"
                style={{ backgroundColor: '#B0193F', color: '#fff' }}
                loading={loading}
                content="Registrarse"
                disabled={disabled() || loading}
                onClick={handleMutation} />
              <br />
            </div>
          )}
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

const CREATE_USER = gql`
  mutation createUser($input: CrearMultiUsuarioInput!){
    crearMultiUsuario(input: $input){
      id
    }
  }`;

const FIELDS_USER = gql`query fieldUser {
  tipoDocumento: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 5 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
  estadoCivil: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 6 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
  estrato: multiValorParametros(filtro: { estado: ACTIVO, idParametro: 7 }) {
    value: id
    text: valorParametro
    key: id
  }
  regimenSalud: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 8 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
  nivelFormacion: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 9 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
  ocupacion: multiValorParametros(filtro: { estado: ACTIVO, idParametro: 10 }) {
    value: id
    text: valorParametro
    key: id
  }
  discapacidad: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 11 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
  multicularidad: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 12 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
}`;


export default CreateUser;