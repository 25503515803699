import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { Button, Dimmer, Form, Grid, Header, Icon, Loader, Message, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import { uri } from "../../../client";
import CameraDialog from "../../../Components/CameraDialog";
import InputTypePassword from "../../../Components/InputTypePassword";
import { setData, toBase64 } from "../../../Utils";
//import { EMAIL_REGEX } from "../../../Utils/Constants";
import Cities from "../../CreateUser/Cities";
import CivilStatus from "../../CreateUser/CivilStatus";
import Disability from "../../CreateUser/Disability";
import DocumentType from "../../CreateUser/DocumentType";
import HealthRegimen from "../../CreateUser/HealthRegimen";
import LevelEducation from "../../CreateUser/LevelEducation";
import Multiculturalities from "../../CreateUser/Multiculturalities";
import Occupation from "../../CreateUser/Occupation";
import Stratum from "../../CreateUser/Stratum";

const options = [
  { text: "Masculino", value: "Masculino", key: "Masculino" },
  { text: "Femenino", value: "Femenino", key: "Femenino" },
  { text: "Otro", value: "Otro", key: "Otro" },
];

const Content = ({ data }) => {
  const { me } = data;
  const idMulticulturalities = Array.isArray(me?.multicularidades) ? me?.multicularidades.map(v => v.id) : []
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [route, setRoute] = useState('profile');
  // const [firstName, setFirstName] = useState(me?.primerNombre || "");
  //const [secondName, setSecondName] = useState(me?.segundoNombre || "");
  // const [firstSurname, setFirstSurname] = useState(me?.primerApellido || "");
  //const [secondSurname, setSecondSurname] = useState(me?.segundoApellido || "");
  const [document, setDocument] = useState(me?.documento || "");
  //const [email, setEmail] = useState(me?.email || "");
  const [phone, setPhone] = useState(me?.telefono || "");
  const [gender, setGender] = useState(me?.genero || "");
  const [civilStatus, setCivilStatus] = useState(me?.estadoCivil?.id || "");
  const [address, setAddress] = useState(me?.direccion || "");
  const [stratum, setStratum] = useState(me?.estrato?.id || "");
  const [healthRegimen, setHealthRegimen] = useState(me?.regimenSalud?.id || "");
  const [levelEducation, setLevelEducation] = useState(me?.nivelFormacion?.id || "");
  const [occupation, setOccupation] = useState(me?.ocupacion?.id || "");
  const [disability, setDisability] = useState(me?.discapacidad?.id || "");
  const [multiculturalities, setMulticulturalities] = useState(idMulticulturalities);
  const [cityDocument, setCityDocument] = useState(me?.ciudadDocumentoExpedicion?.id || "");
  const [cityUser, setCityUser] = useState(me?.ciudad?.id || "");
  const [dateBirth, setDateBirth] = useState(me?.fechaNacimiento || "");
  const [dateDocument, setDateDocument] = useState(me?.fechaDocumentoExpedicion || "");
  const [documentType, setDocumentType] = useState(me?.tipoDocumento?.id || "");
  const [open, setOpen] = useState(false);
  const [readOnlyPhoto, setReadOnlyPhoto] = useState(me?.url_imagen ? true : false);
  const [photo, setPhoto] = useState(me?.url_imagen || {});


  const onCompleted = ({ actualizarPerfil }) => {
    setLoading(false);
    setData("meMulti", actualizarPerfil);
    Swal.fire('Su cuenta se ha actualizado correctamente.', '', 'success')
      .then(() => window.location.reload())
  };

  const onCompletedChangePassword = ({ cambiarContrasena }) => {
    setLoading(false);
    setOldPassword("");
    setNewPassword("");
    setRepeatPassword("");
    Swal.fire(cambiarContrasena, '', 'success');
  };

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        resolve(base64data);
      }
    });
  }

  
  const {
    loading: loadingProfile,
    error: errorProfile,
    data: dataProfile,
    refetch,
  } = useQuery(PROFILE_CHECK);

  
  const [mutationCheck] = useMutation(UPDATE_PROFILE_CHECK);
  const [mutation] = useMutation(UPDATE_PROFILE, { onCompleted, onError });
  const [mutationChangePassword] = useMutation(CHANGE_PASSWORD, { onCompleted: onCompletedChangePassword, onError });
  const handleMutation = async () => {
    setLoading(true);
    const input = { };
    //if (firstName.trim()) input.primerNombre = firstName;
    //if (secondName.trim()) input.segundoNombre = secondName;
    //if (firstSurname.trim()) input.primerApellido = firstSurname;
    //if (secondSurname.trim()) input.segundoApellido = secondSurname;
    if (document.trim()) input.documento = document;
    // if (EMAIL_REGEX.test(email)) input.email = email;
    if (phone.trim()) input.telefono = phone;
    if (gender.trim()) input.genero = gender;
    if (documentType.trim()) input.idTipoDocumento = documentType;
    if (civilStatus.trim()) input.idEstadoCivil = civilStatus;
    if (dateBirth.trim()) input.fechaNacimiento = dateBirth;
    if (stratum.trim()) input.idEstrato = stratum;
    if (healthRegimen.trim()) input.idRegimenSalud = healthRegimen;
    if (levelEducation.trim()) input.idNivelFormacion = levelEducation;
    if (occupation.trim()) input.idOcupacion = occupation;
    if (disability.trim()) input.idDiscapacidad = disability;
    if (dateDocument.trim()) input.fechaDocumentoExpedicion = dateDocument;
    if (cityUser.trim()) input.idCiudad = cityUser;
    if (cityDocument.trim()) input.idCiudadDocumentoExpedicion = cityDocument;
    if (address.trim()) input.direccion = address;
    if (multiculturalities.length > 0) input.multicularidades = multiculturalities;
    if (typeof photo !== 'string') {
      input.file = await toBase64(photo);
    } else {
      input.file = await getBase64FromUrl( uri + photo )
    }
    mutation({ variables: { input } });
  }


  
  const showAlert = () => {
    if (
      dataProfile &&
      dataProfile?.me?.fechaTerminosYCondicionesNuevos == null
    ) {
      Swal.fire({
        icon: "warning",
        html: "Hemos actualizado nuestra Política y tratamientos de datos. Al continuar utilizando nuestros servicios, acepta estos términos actualizados que puede consultar en nuestro <a href='https://multidiomas.edu.co/images/manual_politicas.pdf' target='_blank'>Manual de Políticas y Procedimientos  Habeas Data</a>",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#B0193F",
        allowEnterKey: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const input = {};
          input.fechaTerminosYCondicionesNuevos = new Date();
          await mutationCheck({ variables: { input } });
          window.location.reload();
        }
      });
    }
  };

  showAlert();

  const handleMutationChangePassword = () => {
    setLoading(true);
    mutationChangePassword({
      variables: {
        oldPassword,
        newPassword
      }
    })
  }
  const { loading: loadingFields, error: errorFields, data: dataFields } = useQuery(FIELDS_USER);

  /*const uploadPhoto =()=>{
    Swal.fire({
      icon: 'question',
      text: 'Cargar o tomar una foto',
      input:'file',
      inputAttributes:{
        accept:".jpg, .png, .jpeg|image/*"
      },
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Cargar',
      denyButtonText: 'Tomar una foto',
    }).then((result)=>{
      if (result.isConfirmed) {
        if (result.value!==null) {
          setPhoto(result.value)
          setReadOnlyPhoto(true);
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'debe cargar la foto.'
          });
          return;
        }
      }else if (result.isDenied) {
        setOpen(true)
      }
    });
  }*/

  return (
    <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }} verticalAlign='middle'>
      <Grid.Column style={{ display: 'flex', maxWidth: 1000, }}>
        <Segment>
          <Header textAlign="center">Actualización de datos y foto de usuario<Header.Subheader textAlign="center">Por favor, presione la imagen de perfil para cargar/actualizar su foto</Header.Subheader></Header>
          
          <div style={{ textAlign: 'center', marginBottom: 30, }}>
            <CameraDialog
              open={open}
              setOpen={setOpen}
              setReadOnlyPhoto={setReadOnlyPhoto}
              setPhoto={setPhoto}
            />
            <button onClick={/*uploadPhoto*/()=>{setOpen(true)}} style={{ cursor: 'pointer', background: 'none', border: 0 }}>
              {
                readOnlyPhoto ?
                  <img src={typeof photo === 'string' ?
                    uri + photo :
                    URL.createObjectURL(photo)
                  } alt="foto" width="100" height="100" style={{ borderRadius: "50%" }} />
                  :
                  <Icon style={{ color: '#B0193F' }} name='user' size='huge' />
              }
            </button>
            <br />
            <br />
            <br />
            <div>
              <Button
                onClick={() => setRoute('profile')}
                style={{
                  backgroundColor: route === "profile" ? '#B0193F' : null,
                  color: '#fff'
                }}>
                Editar perfil
              </Button>
              <Button
                onClick={() => setRoute('password')}
                style={{
                  backgroundColor: route === "password" ? '#B0193F' : null,
                  color: '#fff'
                }} >
                Cambiar contraseña
              </Button>
            </div>
          </div>
          {route === "password" && (
            <Form>
              <InputTypePassword
                label="Contraseña antigua"
                placeholder="Contraseña antigua*"
                value={oldPassword}
                required
                onChange={(e, { value }) => setOldPassword(value)} />
              <InputTypePassword
                label="Contraseña nueva"
                placeholder="Contraseña nueva*"
                error={newPassword !== repeatPassword}
                value={newPassword}
                required
                onChange={(e, { value }) => setNewPassword(value)} />
              <InputTypePassword
                label="Confirmar contraseña"
                placeholder="Confirmar contraseña *"
                error={newPassword !== repeatPassword}
                value={repeatPassword}
                required
                onChange={(e, { value }) => setRepeatPassword(value)} />
              <Form.Button
                loading={loading}
                style={{ backgroundColor: '#B0193F', color: '#fff' }}
                disabled={!oldPassword.trim() || !newPassword.trim() || !repeatPassword.trim() || newPassword !== repeatPassword || loading}
                onClick={handleMutationChangePassword}>
                Guardar
              </Form.Button>
            </Form>
          )}
          {route === "profile" && (
            <>
              <Grid stackable columns={2} >
                <Grid.Column>
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        value={document}
                        iconPosition='left'
                        icon={<Icon name='address card' style={{ color: '#B0193F' }} />}
                        onChange={(e, { value }) => setDocument(value)}
                        label="Documento"
                        placeholder="Documento" />
                      <DocumentType
                        value={documentType}
                        label="Tipo de documento"
                        onChange={(e, { value }) => setDocumentType(value)}
                        loading={loadingFields}
                        error={errorFields}
                        data={dataFields} />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Cities
                        label="Lugar documento de expedición"
                        value={cityDocument}
                        onChange={(value) => setCityDocument(value)}
                        placeholder="Lugar documento de expedición *" />
                      <Form.Input
                        fluid
                        label="Fecha documento de expedición"
                        type="date"
                        value={dateDocument}
                        onChange={(e, { value }) => setDateDocument(value)}
                        max={new Date().toISOString().split("T")[0]}
                        required
                        placeholder="Fecha documento de expedición *" />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Cities
                        label="Lugar de nacimiento"
                        value={cityUser}
                        onChange={(value) => setCityUser(value)}
                        placeholder="Lugar de nacimiento *" />
                      <Form.Input
                        fluid
                        label="Fecha de nacimiento"
                        type="date"
                        value={dateBirth}
                        onChange={(e, { value }) => setDateBirth(value)}
                        max={new Date().toISOString().split("T")[0]}
                        required
                        placeholder="Fecha de nacimiento *" />
                    </Form.Group>
                    {/* <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        value={firstName}
                        label="Primer nombre"
                        onChange={(e, { value }) => setFirstName(value)}
                        placeholder="Primer nombre" />
                      <Form.Input
                        fluid
                        value={secondName}
                        onChange={(e, { value }) => setSecondName(value)}
                        label="Segundo nombre"
                        placeholder="Segundo nombre" />
                    </Form.Group>*/}
                    {/* <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        value={firstSurname}
                        onChange={(e, { value }) => setFirstSurname(value)}
                        label="Primer apellido"
                        placeholder="Primer apellido" />
                      <Form.Input
                        fluid
                        value={secondSurname}
                        onChange={(e, { value }) => setSecondSurname(value)}
                        label="Segundo apellido"
                        placeholder="Segundo apellido" />
                  </Form.Group>*/}
                    <Form.Group widths="equal">
                      <Form.Dropdown
                        fluid
                        selection
                        value={gender}
                        onChange={(e, { value }) => setGender(value)}
                        options={options}
                        label="Género"
                        placeholder="Género" />
                      <Form.Input
                        fluid
                        required
                        label="Dirección"
                        value={address}
                        onChange={(e, { value }) => setAddress(value)}
                        placeholder="Dirección *" />
                    </Form.Group>
                  </Form>
                </Grid.Column>
                <Grid.Column>
                  <Form>
                    <Form.Group widths="equal">
                      {/* <Form.Input
                        fluid
                        value={email}
                        iconPosition='left'
                        icon={<Icon name='mail' style={{ color: '#B0193F' }} />}
                        onChange={(e, { value }) => setEmail(value)}
                        label="Correo electrónico"
                     placeholder="Correo electrónico" />*/}
                      <Form.Input
                        fluid
                        value={phone}
                        iconPosition='left'
                        icon={<Icon name='phone' rotated="clockwise" style={{ color: '#B0193F' }} />}
                        onChange={(e, { value }) => setPhone(value)}
                        label="Teléfono"
                        placeholder="Teléfono" />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CivilStatus
                        value={civilStatus}
                        onChange={(e, { value }) => setCivilStatus(value)}
                        loading={loadingFields}
                        error={errorFields}
                        data={dataFields} />
                      <Stratum
                        value={stratum}
                        onChange={(e, { value }) => setStratum(value)}
                        loading={loadingFields}
                        error={errorFields}
                        data={dataFields} />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <HealthRegimen
                        value={healthRegimen}
                        onChange={(e, { value }) => setHealthRegimen(value)}
                        loading={loadingFields}
                        error={errorFields}
                        data={dataFields} />
                      <LevelEducation
                        value={levelEducation}
                        onChange={(e, { value }) => setLevelEducation(value)}
                        loading={loadingFields}
                        error={errorFields}
                        data={dataFields} />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Occupation
                        value={occupation}
                        onChange={(e, { value }) => setOccupation(value)}
                        loading={loadingFields}
                        error={errorFields}
                        data={dataFields} />
                      <Disability
                        value={disability}
                        onChange={(e, { value }) => setDisability(value)}
                        loading={loadingFields}
                        error={errorFields}
                        data={dataFields} />
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid>
              <Form>
                <Form.Group widths="equal">
                  <Multiculturalities
                    value={multiculturalities}
                    onChange={(e, { value }) => setMulticulturalities(value)}
                    loading={loadingFields}
                    error={errorFields}
                    data={dataFields} />
                </Form.Group>
              </Form>
              <div style={{ textAlign: 'center' }}>
                <Form.Button
                  loading={loading}
                  style={{ backgroundColor: '#B0193F', color: '#fff' }}
                  disabled={loading}
                  onClick={handleMutation}>Guardar</Form.Button>
                <p>Al hacer clic en guardar, aceptas que la información es <span style={{ color: '#B0193F' }}>verídica</span></p>
              </div>
            </>
          )}
        </Segment>
      </Grid.Column >
    </Grid >
  );
}

const Profile = () => {

  const { loading, error, data } = useQuery(PROFILE);

  if (loading) return (
    <div>
      <Dimmer active inverted>
        <Loader size='large'>Cargando</Loader>
      </Dimmer>
    </div>
  );

  if (error) return <Message>Ha ocurrido un error.</Message>

  return <Content data={data} />

}

const PROFILE = gql`
 query profile {
    me {
      id
      nombre
      documento
      primerNombre
      segundoNombre
      primerApellido
      segundoApellido
      nombreExterno
      email
      telefono
      genero
      direccion
      valorProcedencia {
        id
        valorParametro
      }
      multiGrupoUsuario {
        id
        nombre
      }
      tipoDocumento{
        id
        valorParametro
      }
      estadoCivil{
        id
        valorParametro
      }
      fechaNacimiento
      estrato{
        id
        valorParametro
      }
      regimenSalud{
        id
        valorParametro
      }
      nivelFormacion{
        id
        valorParametro
      }
      ocupacion{
        id
        valorParametro
      }
      discapacidad{
        id
        valorParametro
      }
      fechaDocumentoExpedicion
      ciudad{
        id
        nombre
      }
      ciudadDocumentoExpedicion{
        id
        nombre
      }
      multicularidades{
        id
        valorParametro
      }
      url_imagen
    }
  }`;

const FIELDS_USER = gql`query fieldUser {
  tipoDocumento: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 5 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
  estadoCivil: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 6 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
  estrato: multiValorParametros(filtro: { estado: ACTIVO, idParametro: 7 }) {
    value: id
    text: valorParametro
    key: id
  }
  regimenSalud: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 8 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
  nivelFormacion: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 9 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
  ocupacion: multiValorParametros(filtro: { estado: ACTIVO, idParametro: 10 }) {
    value: id
    text: valorParametro
    key: id
  }
  discapacidad: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 11 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
  multicularidad: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 12 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
}`;

const UPDATE_PROFILE = gql`
    mutation updateProfile($input: ActualizarMultiUsuarioInput!) {
      actualizarPerfil(input: $input) {
        id
        nombre
        primerNombre
        primerApellido
        documento
        email
        valorProcedencia{
          id
          valorParametro
        }
        multiGrupoUsuario {
          id
          nombre
        }
        url_imagen
      }
    }`;

const CHANGE_PASSWORD = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    cambiarContrasena(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;



const UPDATE_PROFILE_CHECK = gql`
  mutation updateProfile($input: ActualizarMultiUsuarioInput!) {
    actualizarPerfil(input: $input) {
      id
    }
  }
`;

const PROFILE_CHECK = gql`
  query profile {
    me {
      fechaTerminosYCondicionesNuevos
    }
  }
`;

export default Profile;
