import React from "react";
import { Form } from "semantic-ui-react";

const DocumentType = ({ data, loading, error, value, onChange, label }) => {
  if (loading) return (
    <Form.Dropdown
      label={label}
      fluid
      required
      loading
      selection
      placeholder="Tipo de documento *"
      options={[]} />
  );

  if (error) return (
    <Form.Dropdown
      label={label}
      fluid
      required
      error
      selection
      placeholder="Tipo de documento *"
      options={[]} />
  );
  const { tipoDocumento } = data;

  return (
    <Form.Dropdown
      label={label}
      fluid
      required
      search
      value={value}
      selection
      onChange={onChange}
      placeholder="Tipo de documento *"
      options={tipoDocumento} />
  );
}

export default DocumentType;