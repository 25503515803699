import React, { useEffect, useState } from "react";
import { Form, Modal, Input, List, Message } from "semantic-ui-react";

const InputSearch = ({
  value,
  onChange,
  loading,
  error,
  inputError,
  placeholder,
  label,
  data = []
}) => {
  const index = data.findIndex(v => v.value === value);
  const [selected, setSelected] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [open, setOpen] = useState(false);

  const handleSelected = (_v) => {
    const i = data.findIndex(v => v.value === _v?.value);
    setSelected(data[i]);
    if (typeof onChange === "function") onChange(_v?.value || "");
    setFilterValue("");
    setOpen(false);
  }

  useEffect(() => {
    setSelected(data[index] || "");
  }, [data,index])

  if (loading) return (
    <Form.Field>
      <label>{label}</label>
      <Form.Input
        loading
        value="Cargando..."
        disabled
        placeholder={placeholder} />
    </Form.Field>
  );


  if (error) return (
    <Form.Field>
      <label>{label}</label>
      <Form.Input
        error
        value="Lo sentimos, ha ocurrido un error."
        disabled
        placeholder={placeholder} />
    </Form.Field>
  );

  const _data = data?.filter(v => v?.text?.toLocaleLowerCase()?.includes(filterValue?.toLocaleLowerCase()));

  return (
    <>
      {!selected && (
        <Form.Field>
          <label>{label}</label>
          <Form.Button fluid onClick={() => setOpen(true)} content={placeholder} />
        </Form.Field>
      )}
      {selected && (
        <Form.Field>
          <label>{label}</label>
          <Form.Input
            error={inputError}
            onFocus={() => setOpen(true)}
            icon={{
              name: 'delete',
              onClick: () => handleSelected(""),
              link: true
            }}
            value={selected?.text} />
        </Form.Field>
      )}
      <Modal onClose={() => setOpen(false)} size="small" open={open}>
        <Modal.Header>
          <Input
            label="Buscar"
            placeholder={placeholder}
            labelPosition='right'
            onChange={(e, { value }) => setFilterValue(value)} fluid />
        </Modal.Header>
        <Modal.Content scrolling>
          {_data?.length <= 0 && (
            <Message>No se encontro ningún resultado.</Message>
          )}
          <List link relaxed>
            {_data.map((v, key) => (
              <List.Item
                icon="certificate"
                content={v?.text}
                onClick={() => handleSelected(v)}
                as='a'
                key={key} />
            ))}
          </List>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default InputSearch;