export const getData = (key) => {
  const data = localStorage.getItem(key);
  if (!data) return null;

  return JSON.parse(data);
};

export const setData = (key, value) => {
  const data = JSON.stringify(value);
  localStorage.setItem(key, data);
};

export const formatCurrency = (amount = 0, simpleFormat = true, currency = 'COP', locales = 'co-CO') => {
  if (simpleFormat) return new Intl.NumberFormat().format(amount);


  return new Intl.NumberFormat(
    locales,
    {
      style: 'currency',
      currency
    }).format(amount);
};

export const paymentMethod = method => {
  switch (method) {
    case "CREDIT_CARD":
      return "Tarjeta de Crédito";
    case "PSE":
      return "Transferencia bancaria PSE";
    case "ACH":
      return "Débito ACH";
    case "DEBIT_CARD":
      return "Tarjeta débito";
    case "CASH":
      return "Pago en efectivo";
    case "REFERENCED":
      return "Pago referenciado";
    case "BANK_REFERENCED":
      return "Pago en bancos";
    case "SPEI":
      return "Transferencia bancaria SPEI";
    default:
      return "- - - - -";
  }
}

export const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});