import React, { useState } from "react";
import { Form } from "semantic-ui-react";

const InputTypePassword = ({ error, onChange, value, required = false, label, placeholder }) => {
  const [type, setType] = useState("password")
  const handleType = () => {
    if (type === "password") setType("text")
    if (type === "text") setType("password")
  }
  return (
    <Form.Input
      required={required}
      label={label}
      type={type}
      error={error}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      icon={{
        onClick: handleType,
        name: type === "password" ? "eye slash" : "eye",
        link: true,
      }}
    />
  );
};

export default InputTypePassword;