import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Button, Icon, Image, Menu } from "semantic-ui-react";
import Swal from "sweetalert2";
import { client, uri } from "../../../client";
import { getData, setData } from "../../../Utils";
import LOGO_MULTI from "../../../assets/logo-multi.png";
import { useMediaQuery } from "react-responsive";

const MenuBar = ({ history, match }) => {
  const me = getData("meMulti");
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [activeItem, setActiveItem] = useState("home");

  const success = (data) => {
    if (!data.me.url_imagen) {
      if (me.url_imagen) {
        let _me = { ...me };
        _me.url_imagen = null;
        setData("meMulti", _me);
        Swal.fire({
          icon:"error",
          showConfirmButton:true,
          confirmButtonText: "Aceptar",
          text:"Se ha deshabilitado su foto debido a que no cumple con los estándares de calidad requeridos en la plataforma, por favor, suba una nueva foto para poder seguir utilizando la plataforma."
        })
      }
      if (activeItem !== "profile") {
        history.replace(`/dashboard/profile`);
        setActiveItem("profile")
      }
    }
  };

  const { refetch }  = useQuery(PROFILE, { onCompleted: success });
  
  useEffect(() => {
    refetch()
    //eslint-disable-next-line
  }, [activeItem])

  const handleItemClick = (e, { name }) => {
    if (!me.url_imagen) {
      Swal.fire({
        icon: "warning",
        title: "Aviso",
        text: "Para poder utilizar la plataforma debe cargar una foto de perfil.",
      });
      return;
    }
    setActiveItem(name);
    history.replace(`${match.url}/${name}`);
  };

  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onCompleted = () => {
    setData("meMulti", null);
    setData("tokenUserMulti", null);
    client.clearStore();
    history.replace("/");
  };

  const [logout] = useMutation(LOGOUT, { onCompleted, onError });

  const handleLogout = () => {
    Swal.fire({
      title: "¿Deseas finalizar la sesión?",
      showDenyButton: true,
      confirmButtonText: "Aceptar",
      denyButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };

  const styleMobile = isMobile ? "center" : null;

  return (
    <>
      <Menu stackable fluid style={{ margin: 0, border: "none" }} size="large">
        <Menu.Item style={{ justifyContent: styleMobile }}>
          <Image size="small" src={LOGO_MULTI} alt="logo multidiomas" />
        </Menu.Item>
        <Menu.Item
          style={{
            color: "#045A73",
            fontWeight: "bold",
            justifyContent: styleMobile,
          }}
          name="Procedencia"
        >
          <Icon size="large" name="university" />
          {me?.valorProcedencia?.valorParametro}
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item
            style={{
              color: "#045A73",
              fontWeight: "bold",
              justifyContent: styleMobile,
            }}
            name="profile"
            active={activeItem === "profile"}
            onClick={handleItemClick}
          >
            {me.url_imagen ? (
              <img
                src={uri + me.url_imagen}
                alt="foto"
                height="40"
                style={{ borderRadius: "50%", marginLeft: 5, marginRight: 5 }}
              />
            ) : (
              <Icon size="large" name="user" />
            )}
            {me?.primerNombre + " " + me?.primerApellido}
          </Menu.Item>
          <Menu.Item style={{ justifyContent: styleMobile }}>
            <Button
              onClick={handleLogout}
              icon="sign-out alternate"
              color="google plus"
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Menu
        stackable
        fluid
        style={{
          margin: 0,
          backgroundColor: "#045A73",
          border: "none",
          borderRadius: "inherit",
        }}
        size="large"
      >
        <Menu.Item
          name="home"
          style={{ justifyContent: styleMobile }}
          active={activeItem === "home"}
          onClick={handleItemClick}
        >
          <Button
            content="Inicio"
            icon="home"
            style={{ backgroundColor: "#ffff", color: "#045A73" }}
          />
        </Menu.Item>
        <Menu.Item
          name="payments"
          style={{ justifyContent: styleMobile }}
          active={activeItem === "payments"}
          onClick={handleItemClick}
        >
          <Button
            content="Mis pagos"
            icon="payment"
            style={{ backgroundColor: "#ffff", color: "#045A73" }}
          />
        </Menu.Item>
        <Menu.Item
          name="requests"
          style={{ justifyContent: styleMobile }}
          active={activeItem === "requests"}
          onClick={handleItemClick}
        >
          <Button
            content="Mis solicitudes"
            icon="file alternate"
            style={{ backgroundColor: "#ffff", color: "#045A73" }}
          />
        </Menu.Item>
      </Menu>
    </>
  );
};

const LOGOUT = gql`
  mutation logout {
    cerrarSesion
  }
`;

const PROFILE = gql`
  query profile {
    me {
      id
      url_imagen
    }
  }
`;

export default MenuBar;
