import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import React, {useState} from "react";
import { Icon } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTablePagination";
import DocumentResponse from "../../../Components/DocumentResponse";
import { formatCurrency, paymentMethod } from "../../../Utils";
import Swal from "sweetalert2";
const Payments = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  
  const {
    loading: loadingProfile,
    error: errorProfile,
    data: dataProfile,
    refetch,
  } = useQuery(PROFILE);

  const { loading, error, data } = useQuery(PAYMENTS, {
    variables: { pagina: {limit:pageLimit, number: currentPage} },
    fetchPolicy: "network-only"
  });
  const [mutation] = useMutation(UPDATE_PROFILE);


  const columns = [
    {
      header: 'Descripción',
      accessor: 'descripcion'
    },
    {
      header: 'Valor',
      accessor: 'valor',
      render: amount => formatCurrency(amount)
    },
    {
      header: 'Fecha',
      accessor: 'fechaHora',
      render: date => moment(date).format('DD-MM-YYYY h:mm:ss A')
    },
    {
      header: 'Fecha de confirmación',
      accessor: 'fechaConfirmacion',
      render: date => date ? moment(date).format('DD-MM-YYYY h:mm:ss A') : ""
    },
    {
      header: 'Referencia de pago',
      accessor: 'referenciaPago'
    },
    {
      header: 'Moneda',
      accessor: 'moneda'
    },
    {
      header: 'Metodo de pago',
      accessor: 'metodoPago',
      render: data => paymentMethod(data)
    },
    {
      header: 'Estado',
      accessor: 'estadoPago'
    },
    {
      header: 'Detalle',
      accessor: 'id',
      render: id => (
        <DocumentResponse
          type="payment"
          trigger={
            <Icon
              name="arrow alternate circle right"
              link />
          }
          id={id} />
      )
    }
  ];

  
  const showAlert = () => {
    if (
      dataProfile &&
      dataProfile?.me?.fechaTerminosYCondicionesNuevos == null
    ) {
      Swal.fire({
        icon: "warning",
        html: "Hemos actualizado nuestra Política y tratamientos de datos. Al continuar utilizando nuestros servicios, acepta estos términos actualizados que puede consultar en nuestro <a href='https://multidiomas.edu.co/images/manual_politicas.pdf' target='_blank'>Manual de Políticas y Procedimientos  Habeas Data</a>",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#B0193F",
        allowEnterKey: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const input = {};
          input.fechaTerminosYCondicionesNuevos = new Date();
          await mutation({ variables: { input } });
          window.location.reload();
        }
      });
    }
  };

  showAlert();

  return (
    <CustomTable
      error={error}
      loading={loading}
      columns={columns}
      data={data?.multiPagosSesion?.items}
      currentPage={currentPage}
      onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
      pageLimit={pageLimit}
      setPageLimit={setPageLimit}
      setCurrentPage={setCurrentPage}
      totalPages={data?.multiPagosSesion?.totalPages || 0}/>
  );
}

const PAYMENTS = gql`
  query payments($estadoPago: EstadoPago, $pagina: PageInput!) {
    multiPagosSesion(
      filtro: { estado: ACTIVO, estadoPago: $estadoPago },
      orden: { direccion: DESC , campo: FECHA_CREACION },
      pagina: $pagina
    ) {
      count
      totalPages
      items {
        id
      valor
      fechaHora
      referenciaPago
      descripcion
      estadoPago
      signature
      fechaConfirmacion
      moneda
      metodoPago
      documento {
        id
        nombre
      }
      }
      page {
        number
        limit
      }
    }
  }`;

  
const UPDATE_PROFILE = gql`
mutation updateProfile($input: ActualizarMultiUsuarioInput!) {
  actualizarPerfil(input: $input) {
    id
  }
}
`;

const PROFILE = gql`
query profile {
  me {
    fechaTerminosYCondicionesNuevos
  }
}
`;

export default Payments;