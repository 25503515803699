import { Button, Segment } from "semantic-ui-react";

const ConfirmationMessagePayu = ({ history, location }) => {
  const parseTransactionState = (state) => {
    switch (state) {
      case "4":
        return "Aprobada";
      case "6":
        return "Declinada";
      default:
        return "- - - - ";
    }
  }

  let params = (new URLSearchParams(location?.search));
  let description = params.get('description');
  let transactionState = params.get('transactionState');

  return (
    <Segment style={{ margin: 30 }}>
      <h5>Transacción {parseTransactionState(transactionState)}</h5>
      <h5>Descripción: {description}</h5>
      <p>La transacción se encuentra en estado <span style={{ fontWeight: 'bold', color: '#B0193F' }}>{parseTransactionState(transactionState)}</span>, recibirá una confirmación por correo electrónico.</p>
      <br />
      <Button
        style={{ backgroundColor: '#B0193F', color: '#fff' }}
        onClick={() => history.push('/dashboard/payments')}
        content="Ir a mis pagos" />
    </Segment>
  );
}

export default ConfirmationMessagePayu;