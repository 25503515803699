import { gql, useQuery } from "@apollo/client";
import React from "react";
import InputSearch from "../../Components/InputSearch";

const Cities = ({
  value,
  onChange,
  label,
  placeholder
}) => {

  const { loading, error, data } = useQuery(CITIES);


  return (
    <InputSearch
      loading={loading}
      error={error}
      label={label}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      data={data?.MultiPaises[0]?.ciudades} />
  );
}
const CITIES = gql`
query cities{
  MultiPaises(filtro: { estado: ACTIVO }) {
    value: id
    text: nombre
    key: id
    ciudades {
      value: id
      text: nombre
      key: id
    }
  }
}`
export default Cities;