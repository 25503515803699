import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Grid, Icon, Segment } from 'semantic-ui-react';
import { getData, setData } from '../../Utils';
import { client } from "../../client";
import LOGO_MULTI from "../../assets/logo-multi.png";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { EMAIL_REGEX } from '../../Utils/Constants';

const errorContent = {
  content: "Ingresa una dirección de correo electrónico válida.",
  pointing: 'below',
};


const Login = ({ history, location }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  let params = (new URLSearchParams(location?.search));
  const verifyToken = params.get('verifyToken') === "true";
  useEffect(() => {
    if (getData("tokenUserMulti") && getData("meMulti").url_imagen !== null) {
      history.replace("/dashboard/home");
    } else if (getData("tokenUserMulti") && getData("meMulti").url_imagen === null) {
      Swal.fire({
        icon: 'warning',
        title: 'Aviso',
        text: 'A continuación se le enviará a su perfil, por favor cargue su imagen de usuario para poder acceder al sistema'
      }).then(() => history.replace("/dashboard/profile"));
    }
  }, [history]);
  useEffect(() => {
    if (verifyToken) {
      Swal.fire({
        text: 'Su cuenta ha sido verificada exitosamente.',
        icon: 'success'
      });
    }
  }, [verifyToken])
  const onCompleted = ({ crearSesion }) => {
    setData("tokenUserMulti", crearSesion);
    client.query({ query: ME })
      .then(res => {
        if (res.data) {
          setData("meMulti", res.data.me);
          if (res.data.me.url_imagen !== null) {
            history.replace("/dashboard/home");
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Aviso',
              text: 'A continuación se le enviará a su perfil, por favor cargue su imagen de usuario para poder acceder al sistema'
            }).then(() => history.replace("/dashboard/profile"));
          }
        }
      })
      .catch(console.error)
  };
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [login] = useMutation(LOGIN, { onCompleted, onError });
  const handleMutation = () => {
    login({
      variables: {
        email: email.trim(),
        password
      }
    });
  };
  return (
    <Grid className="login" textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Form size='large'>
          <Segment stacked>
            <div style={{ margin: 20, textAlign: 'center' }}>
              <img src={LOGO_MULTI} alt="logo multidiomas" />
            </div>
            <Form.Input
              icon={<Icon name='user' style={{ color: '#B0193F' }} />}
              value={email}
              error={email.trim() && !EMAIL_REGEX.test(email) ? errorContent : null}
              iconPosition='left'
              placeholder='Correo'
              onChange={(e, { value }) => setEmail(value)} />
            <Form.Input
              value={password}
              icon={<Icon name='lock' style={{ color: '#B0193F' }} />}
              iconPosition='left'
              placeholder='Contraseña'
              type='password'
              onChange={(e, { value }) => setPassword(value)} />
            <br />
            <Button
              style={{ backgroundColor: '#B0193F', color: '#fff' }}
              onClick={handleMutation}
              disabled={!EMAIL_REGEX.test(email) || !password.trim()}>
              Iniciar sesión
            </Button>
            <br />
            <br />
            <Link style={{ color: '#B0193F' }} to="/recover-account">
              ¿Has olvidado la contraseña?
            </Link>
            <Divider />
            <Button
              icon="user plus"
              style={{ backgroundColor: '#B0193F', color: '#fff' }}
              onClick={() => history.push("/create-user")}
              content="Registrarse" />
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

const LOGIN = gql`
  mutation crearSesion($email: EmailAddress!, $password: String!) {
    crearSesion(email: $email, password: $password)
  }
`;

const ME = gql`
  query me {
    me {
      id
      nombre
      primerNombre
      primerApellido
      documento
      email
      valorProcedencia{
        id
        valorParametro
      }
      multiGrupoUsuario {
        id
        nombre
      }
      url_imagen
    }
  }
`;

export default Login;