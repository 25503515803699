import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Checkbox, Form, Grid, Input, Message } from "semantic-ui-react";
import Swal from "sweetalert2";
import FormPayu from "../../../Components/FormPayu";
import MultiInput from "../../../Components/MultiInput";
import { formatCurrency } from "../../../Utils";
import { formPayuTest, URL_RESPONSE_PAYU } from "../../../Utils/Constants";

const Inputs = ({ inputs = [], history, document }) => {
  const [listResponse, setListResponse] = useState([]);
  const [amount, setAmount] = useState(0);
  const [fakeAmount, setFakeAmount] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const environment = process.env.NODE_ENV;
  const documentType = document?.tipoDocumento?.valorParametro;
  const idTypePaymentDocument = document?.tipoDocumentoPago?.id;

  const onCompleted = ({ crearRespuestaPago }) => {
    const formData = environment === "production"
      ? crearRespuestaPago
      : formPayuTest(
        crearRespuestaPago?.amount,
        crearRespuestaPago?.description,
        crearRespuestaPago?.referenceCode
      );
    setData(formData);
  };

  const onCompletedRequest = () => {
    Swal.fire(
      {
        title: 'Información',
        input: 'textarea',
        inputValue: document?.mensajeConfirmacion,
        inputAttributes: {
          disabled: true,
          style: "min-height: 270px; max-height: 270px; min-width: 425px;"
        },
        icon: 'info'
      }
    ).then(() => history.replace("/dashboard/requests"));
  }

  const onErrorRequest = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(CREATE_RESPONSE_PAYMENT, { onCompleted, onError });
  const [mutationRequest] = useMutation(CREATE_RESPONSE_REQUEST, { onCompleted: onCompletedRequest, onError: onErrorRequest })
  if (!inputs.length) return <Message>No hay campos para cargar.</Message>;


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const input = {
      nombre: document?.nombre,
      enunciado: document?.enunciado,
      idDocumento: document?.id,
      campos: listResponse.filter(x => x !== undefined)
    };

    Swal.fire({
      title: '¿Deseas realizar está acción?',
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        if (documentType === "Pago") {
          //open option
          if (idTypePaymentDocument === "110") {
            if (amount < 12000) {
              Swal.fire({
                text: 'El monto mínimo permitido es de $ 12000',
                icon: 'warning',
              });
              setLoading(false);
              return;
            }
            input.valor = amount;
          }
          //closed option 
          if (idTypePaymentDocument === "111") {
            input.valor = document?.valor;
          }
          //open and closed option
          if (idTypePaymentDocument === "112") {
            if (checked && amount < 12000) {
              Swal.fire({
                text: 'El monto mínimo permitido es de $ 12000',
                icon: 'warning',
              });
              setLoading(false);
              return;
            }
            input.valor = checked ? amount : document?.valor;
          }

          mutation({ variables: { input } });
        };

        if (documentType === "Solicitud") mutationRequest({ variables: { input } });
      }
      if (!result.isConfirmed) setLoading(false);
    })
  }


  const handleChangeFile = ({
    target: {
      files: [file],
    },
  }, { enunciado, idcampo, name, pos }) => {
    var fileSize = file?.size / 1024 / 1024; // in MiB
    let list = [...listResponse];
    const info = {
      enunciadoCampo: enunciado,
      idCampo: idcampo,
      urlArchivo: file
    }
    if (fileSize > 10) {
      list[pos] = null;
      setListResponse(list);
      return Swal.fire({
        'icon': 'error',
        'title': 'Ops...',
        'text': 'El tamaño del archivo supera los 10mb.'
      });
    }

    list[pos] = info;
    setListResponse(list);
  }


  const handleOnChange = (e, { value, name, enunciado, idcampo, tipo, pos, options }) => {
    const info = {
      enunciadoCampo: enunciado,
      idCampo: idcampo,
    }

    if (tipo === "Texto" || tipo === "Radio" || tipo === "Opcion" || tipo === "Calendario") info.respuestaTexto = value;

    if (tipo==="Opcion - Aforo") {
      info.respuestaTexto = value
      options.forEach(option => {
        if(option.value === value)
        {
          info.fechaAplicacion=option.key
        }
      })
    }

    let list = [...listResponse];
    list[pos] = info;
    setListResponse(list);
  }

  const disabled = () => {
    if (!listResponse.length) return true;
    for (const key in inputs) {
      const v = inputs[key];
      if ((v?.tipo?.valorParametro === "Texto" && v?.obligatorio === true)
        || (v?.tipo?.valorParametro === "Radio" && v?.obligatorio === true)
        || (v?.tipo?.valorParametro === "Opcion" && v?.obligatorio === true)
        || (v?.tipo?.valorParametro === "Calendario" && v?.obligatorio === true)) {
        if (!listResponse[key]?.respuestaTexto.trim()) return true;
      }

      if (v?.tipo?.valorParametro === "Archivo" && v?.obligatorio === true) {
        if (!listResponse[key]?.urlArchivo) return true;
      }
    }
    return false;
  }

  return (
    <Form size="large" onSubmit={handleSubmit}>
      <Grid stackable columns='equal'>
        <Grid.Column>
          {inputs.map((input, key) => (
            <MultiInput
              id={input?.id}
              num={key}
              type={input?.tipo?.valorParametro}
              name={input?.nombre}
              onChange={handleOnChange}
              onChangeFile={handleChangeFile}
              key={key}
              options={input?.opciones}
              required={input?.obligatorio}
              label={input?.enunciado}
              control={Input}
              placeholder={'Rellene la respuesta'} />
          ))}
          {documentType === "Solicitud" && (
            <Button
              style={{ backgroundColor: '#B0193F', color: '#fff' }}
              type='submit' disabled={disabled() || loading}>
              Solicitar
            </Button>
          )}
        </Grid.Column>
        {documentType === "Pago" && (
          <Grid.Column>
            {idTypePaymentDocument !== "110" && (
              <>
                <h3> {document?.mensajeValorAbierto || "Valor a pagar"} </h3>
                <h1> ${formatCurrency(document?.valor, false)}</h1>
              </>
            )}
            {idTypePaymentDocument === "110" && (
              <div>
                <h3> {document?.mensajeValorAbierto || "Digite la cantidad"} </h3>
                <Input
                  fluid
                  onBlur={() => setFakeAmount(formatCurrency(parseFloat(amount)))}
                  value={fakeAmount}
                  onChange={(e, { value }) => {
                    let res = value;
                    if (Number.isNaN(parseFloat(res))) res = 0;
                    setAmount(parseFloat(res));
                    setFakeAmount(parseFloat(res))
                  }}
                  placeholder="Digite el valor a pagar" />
              </div>
            )}
            {idTypePaymentDocument === "112" && (
              <div>
                <Checkbox
                  onChange={(e, { checked }) => setChecked(checked)}
                  checked={checked}
                  label='Otro valor' />
                <br />
                <br />
                <Input
                  disabled={!checked}
                  fluid
                  onBlur={() => setFakeAmount(formatCurrency(parseFloat(amount)))}
                  value={fakeAmount}
                  onChange={(e, { value }) => {
                    let res = value;
                    if (Number.isNaN(parseFloat(res))) res = 0;
                    setAmount(parseFloat(res));
                    setFakeAmount(parseFloat(res))
                  }}
                  placeholder="Digite el valor a pagar" />
              </div>
            )}
            <div style={{ marginTop: 20 }}>
              <Button disabled={disabled() || loading} style={{ backgroundColor: '#B0193F', color: '#fff' }} type='submit'>Pagar</Button>
            </div>
          </Grid.Column>
        )}
      </Grid>
      <FormPayu
        location={URL_RESPONSE_PAYU}
        formData={data} action={(r) => {
          if (r && data) r.submit();
        }} />
    </Form>
  );
};

const CREATE_RESPONSE_PAYMENT = gql`
  mutation createResponsePayment($input: CrearMultiRespuestaPagoInput!) {
    crearRespuestaPago(input: $input) {
      merchantId
      referenceCode
      description
      amount
      tax
      taxReturnBase
      signature
      accountId
      currency
      buyerFullName
      buyerEmail
      test
      shippingAddress
      shippingCity
      shippingCountry
      telephone
      confirmationUrl
    }
  }
`;

const CREATE_RESPONSE_REQUEST = gql`
  mutation createResponseRequest($input: CrearMultiRespuestaSolicitudInput!) {
    crearRespuestaSolicitud(input: $input) {
      id
      estadoSolicitud{
        id
        valorParametro
      }
    }
  }
`;

export default Inputs;

