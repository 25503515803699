import React from "react";
import { Form } from "semantic-ui-react";

const HealthRegimen = ({ data, loading, error, value, onChange }) => {
  if (loading) return (
    <Form.Dropdown
      fluid
      label="Régimen de salud"
      required
      loading
      selection
      placeholder="Régimen de salud *"
      options={[]} />
  );

  if (error) return (
    <Form.Dropdown
      fluid
      label="Régimen de salud"
      required
      error
      selection
      placeholder="Régimen de salud *"
      options={[]} />
  );
  const { regimenSalud } = data;

  return (
    <Form.Dropdown
      fluid
      label="Régimen de salud"
      required
      search
      value={value}
      selection
      onChange={onChange}
      placeholder="Régimen de salud *"
      options={regimenSalud} />
  );
}

export default HealthRegimen;