import React from "react";
import { Form, Radio } from "semantic-ui-react";
import DownloadLink from "./DownloadLink";

const InputsResponse = ({ input, num, }) => {
  const type = input?.campo?.tipo?.valorParametro;

  if (type === "Radio") return (
    <Form.Field style={{ marginTop: 30 }}>
      <label>{`${num}. ${input?.enunciadoCampo}`}</label>
      <Form.Field>
        <Radio
          checked={true}
          label={input?.respuestaTexto} />
      </Form.Field>
    </Form.Field>
  );

  if (type === "Archivo") return (
    <Form.Field style={{ marginTop: 30 }}>
      <label style={{ fontWeight: 'bold' }}>{`${num}. ${input?.enunciadoCampo}`}</label>
      {input?.urlArchivo && (
        <DownloadLink url={input.urlArchivo} />
      )}
    </Form.Field>
  );

  if (type === "Opcion") return (
    <Form.Dropdown
      selection
      value={input?.respuestaTexto}
      label={`${num}. ${input?.enunciadoCampo}`}
      options={[{ text: input?.respuestaTexto, value: input?.respuestaTexto, key: 1 }]}
    />
  );

  return (
    <Form.Input
      value={input?.respuestaTexto}
      label={`${num}. ${input?.enunciadoCampo}`}
    />
  );
}

export default InputsResponse;