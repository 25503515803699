import React from "react";
import { URL_PAYU_PRODUCTION, URL_PAYU_STAGING } from "../Utils/Constants";





const FormPayu = ({ formData = null, action, location }) => {

  if (!formData) return null;

  let URL_PAYU = URL_PAYU_STAGING;
  const environment = process.env.NODE_ENV;

  if (environment === "production") URL_PAYU = URL_PAYU_PRODUCTION;

  return (
    <form method="post" action={URL_PAYU} ref={action}>
      <input name="merchantId" type="hidden" value={formData?.merchantId} />
      <input name="referenceCode" type="hidden" value={formData?.referenceCode} />
      <input name="description" type="hidden" value={formData?.description} />
      <input name="amount" type="hidden" value={formData?.amount} />
      <input name="tax" type="hidden" value={formData?.tax} />
      <input name="taxReturnBase" type="hidden" value={formData?.taxReturnBase} />
      <input name="signature" type="hidden" value={formData?.signature} />
      <input name="accountId" type="hidden" value={formData?.accountId} />
      <input name="currency" type="hidden" value={formData?.currency} />
      <input name="buyerFullName" type="hidden" value={formData?.buyerFullName} />
      <input name="buyerEmail" type="hidden" value={formData?.buyerEmail} />
      <input name="shippingAddress" type="hidden" value={formData?.shippingAddress} />
      <input name="shippingCity" type="hidden" value={formData?.shippingCity} />
      <input name="shippingCountry" type="hidden" value={formData?.shippingCountry} />
      <input name="telephone" type="hidden" value={formData?.telephone} />
      <input name="confirmationUrl" type="hidden" value={formData?.confirmationUrl} />
      {environment !== "production" && <input name="test" type="hidden" value="1"></input>}
      <input name="responseUrl" type="hidden" value={`${location}`} />
    </form>
  );
};

export default FormPayu;