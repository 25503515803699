import React from "react";
import { Form } from "semantic-ui-react";

const Disability = ({ data, loading, error, value, onChange }) => {
  if (loading) return (
    <Form.Dropdown
      fluid
      label="Discapacidad"
      required
      loading
      selection
      placeholder="Discapacidad *"
      options={[]} />
  );

  if (error) return (
    <Form.Dropdown
      fluid
      label="Discapacidad"
      required
      error
      selection
      placeholder="Discapacidad *"
      options={[]} />
  );
  const { discapacidad } = data;

  return (
    <Form.Dropdown
      fluid
      label="Discapacidad"
      required
      search
      value={value}
      selection
      onChange={onChange}
      placeholder="Discapacidad *"
      options={discapacidad} />
  );
}

export default Disability;