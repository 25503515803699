import React from "react";
import {
  Menu,
  Message,
  Pagination,
  Placeholder,
  Table,
  Form,
} from "semantic-ui-react";

const CustomTable = ({
  columns = [],
  data = [],
  loading = false,
  error = false,
  componentError,
  currentPage,
  onPageChange,
  totalPages,
  pageLimit,
  setPageLimit,
  setCurrentPage
}) => {
  const travelObj = (obj = null, path, anyData = false) => {
    if (!path) return obj;
    const pathObj = path.split(".");
    let value = pathObj.reduce((cursor, pathPart) => cursor[pathPart], obj);
    if (anyData) return value;

    return typeof value !== "object" || value !== "function" ? value : "";
  };

  if (error) {
    if (componentError) return componentError;
    return (
      <div style={{ margin: 30, overflowX: 'overlay' }}>
        <Message>Ha ocurrido un error.</Message>
      </div>
    );
  }

  if (loading)
    return (
      <div style={{ margin: 30, overflowX: 'overlay' }}>
        <Table striped>
          <Table.Header>
            <Table.Row>
              {columns.map((column, key) => (
                <Table.HeaderCell
                  key={key}
                >
                  {column.header}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {[...Array(5)].map((_, key) => (
              <Table.Row key={key}>
                {columns.map((v, k) => (
                  <Table.Cell key={k} collapsing>
                    <Placeholder>
                      <Placeholder.Line length="full" />
                      <Placeholder.Line length="full" />
                    </Placeholder>
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );

  if (!data.length)
    return (
      <div style={{ margin: 30, overflowX: 'overlay' }}>
        <Message>No hay datos para mostrar.</Message>
      </div>
    );

  return (
    <div style={{ margin: 30, overflowX: 'overlay' }}>
      <Table striped>
        <Table.Header>
          <Table.Row>
            {columns.map((column, key) => (
              <Table.HeaderCell
                key={key}
              >
                {column.header}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((value, key) => (
            <Table.Row key={value.id}>
              {columns.map((column, key2) => (
                <Table.Cell key={key2} collapsing>
                  {column.render
                    ? column.render(travelObj(value, column.accessor, true))
                    : travelObj(value, column.accessor)}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
        {totalPages === 0 || totalPages === 1 ? null : (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={columns.length}>
                <Form.Dropdown
                  loading={loading}
                  error={error}
                  label="Registros por página"
                  value={pageLimit}
                  onChange={(e, { value }) => {
                      setPageLimit(value)
                      setCurrentPage(1)
                  }}
                  options={[10, 15, 20, 50, 100, 1000, 10000, 50000].map((e) => {
                    return { value: e, label: e };
                  })}
                />
                <Menu floated="right" pagination>
                  <Pagination
                    defaultActivePage={currentPage}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                  />
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </div>
  );
};

export default CustomTable;