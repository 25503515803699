import React from "react";
import { Form } from "semantic-ui-react";

const Multiculturalities = ({ data, loading, error, value, onChange }) => {
  if (loading) return (
    <Form.Dropdown
      fluid
      label="Multiculturalidades"
      required
      loading
      selection
      placeholder="Multiculturalidades *"
      options={[]} />
  );

  if (error) return (
    <Form.Dropdown
      fluid
      label="Multiculturalidades"
      required
      error
      selection
      placeholder="Multiculturalidades *"
      options={[]} />
  );
  const { multicularidad } = data;

  return (
    <Form.Dropdown
      fluid
      label="Multiculturalidades *"
      search
      value={value}
      selection
      multiple
      onChange={onChange}
      placeholder="Multiculturalidades"
      options={multicularidad} />
  );
}

export default Multiculturalities;