import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Container, Dimmer, Header, Icon, Loader, Message, Segment } from "semantic-ui-react";
import { getData } from "../../../Utils";
import Inputs from "./Inputs";
import MDEditor from '@uiw/react-md-editor';
const Document = ({ match: { params: { id } }, location, history }) => {
  const me = getData("meMulti");
  const variables = { id };

  const handleBack = () => {
    history.replace("/dashboard/home");
  }
  if (me?.valorProcedencia?.id) variables.idProcedencia = me?.valorProcedencia?.id;
  const { loading, error, data } = useQuery(DOCUMENT, { variables });

  if (loading) return (
    <div>
      <Dimmer active inverted>
        <Loader size='large'>Cargando</Loader>
      </Dimmer>
    </div>
  );

  if (error) return (
    <div style={{ margin: 30 }}>
      <Message>Ha ocurrido un error.</Message>
    </div>
  );

  const { multiDocumento } = data;

  return (
    <Container >
      <Segment style={{ margin: 20 }}>
        <Header as="h1" style={{ margin: 20 }}>
          <Icon onClick={handleBack} link size='big' name='arrow left' />
          {multiDocumento?.enunciado}
        </Header>
        <div style={{ marginLeft: 20 }}>
          <MDEditor.Markdown className="markDownMulti" source={multiDocumento?.descripcion} />
          <div style={{ padding: 11 }}>
            <h3>Por favor digilenciar todos los campos con <span style={{ color: '#B0193F' }}>*</span></h3>
            <Inputs
              history={history}
              location={location}
              document={multiDocumento}
              inputs={multiDocumento?.campos} />
          </div>
        </div>
      </Segment>
    </Container>
  )
}


const DOCUMENT = gql`
query document($id: ID! $idProcedencia: ID) {
  multiDocumento(id: $id idProcedencia: $idProcedencia) {
    id
    nombre
    enunciado
    descripcion
    urlImagen
    valor
    tipoDocumento {
      id
      valorParametro
    }
    mensajeValorAbierto
    mensajeConfirmacion
    tipoDocumentoPago{
      id
      valorParametro
    }
    estadoSolicitud {
      id
      valorParametro
    }
    valorLibre
    campos {
      id
      nombre
      enunciado
      opciones {
        id
        nombre
        orden
        enunciado
        valor
        pagos
      }
      tipo {
        id
        valorParametro
        multiParametro {
          id
          nombreParametro
        }
        estado
      }
      obligatorio
      estado
    }
  }
}`;
export default Document;