import React from "react";
import { Route } from "react-router-dom";
import Home from "./Home";
import Document from "./Document";
import MenuBar from "./Menu";
import Profile from "./User";
import Payments from "./Payments";
import Requests from "./Requests";
import ConfirmationMessagePayu from "./Payments/ConfirmationMessagePayu";


const Dashboard = ({ match, history }) => {

  return (
    <div >
      <MenuBar match={match} history={history} />
      <div>
        <Route
          component={Home}
          path={`${match.path}/home`} />
        <Route
          component={Profile}
          path={`${match.path}/profile`} />
        <Route
          component={Payments}
          path={`${match.path}/Payments`} />
        <Route
          component={ConfirmationMessagePayu}
          path={`${match.path}/responsePayu`} />
        <Route
          component={Requests}
          path={`${match.path}/Requests`} />
        <Route
          component={Document}
          path={`${match.path}/document/:id`} />
      </div>
    </div>
  )
}

export default Dashboard;