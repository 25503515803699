import React from "react";
import { Icon } from "semantic-ui-react";
const host = process.env.NODE_ENV === 'production' ? "https://solicitudes.multidiomasltda.com:3000" : "http://localhost:4000";
const DownloadLink = ({ url }) => {
  const URI = host + url;
  const handleDownload = () => {
    let a = document.createElement("a");
    a.href = URI;
    a.target = "_blank";
    a.download = true;
    a.click();
  };

  return (
    <div style={{ cursor: 'pointer' }} onClick={handleDownload}>
      <Icon name="download" />
         Descargar archivo
    </div>
  );
};

export default DownloadLink;