import faker from "faker";
import Crypto from "crypto-js";

export const URL_PAYU_PRODUCTION = "https://checkout.payulatam.com/ppp-web-gateway-payu/";
export const URL_PAYU_STAGING = "https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu";
export const EMAIL_REGEX = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/i;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
export const checkDateInvalid = (v) => new Date() > new Date(v);
export const chekcStartDate = date => new Date() < new Date(date);
export const URL_RESPONSE_PAYU = process.env.NODE_ENV === "production" ? "https://solicitudes.multidiomasltda.com/dashboard/responsePayu" : "http://localhost:3000/dashboard/responsePayu";

export const formPayuTest = (amountForm = 15000, description = "Test Payu", referenceCodeForm) => {
  const apiKey = "4Vj8eK4rloUd272L48hsrarnUA";
  const formDataTest = {
    merchantId: "508029",
    referenceCode: referenceCodeForm || faker.name.title().toLowerCase().split(' ').join(''),
    description,
    amount: amountForm,
    tax: 0,
    taxReturnBase: 0,
    accountId: "512321",
    currency: "COP",
    buyerFullName: "",
    buyerEmail: "test@test.com",
    shippingAddress: "",
    shippingCity: "",
    shippingCountry: "",
    telephone: "",
    confirmationUrl: "https://solicitudes.multidiomasltda.com:3000/responsePayu"
  };

  const { merchantId, referenceCode, amount, currency } = formDataTest;

  const signature = Crypto.MD5(
    apiKey + "~" + merchantId + "~" + referenceCode + "~" + amount + "~" + currency
  ).toString();

  formDataTest.signature = signature;

  return formDataTest;
}