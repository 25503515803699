import Login from "./Pages/Login";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import ProtectedRoute from "./Pages/ProtectedRoute";
import './App.css';
import CreateUser from "./Pages/CreateUser";
import RecoverAccount from "./Pages/RecoverAccount";
import RecoverPassword from "./Pages/RecoverAccount/RecoverPassword";
function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          name="Login Page"
          component={Login} />
        <Route
          exact
          path="/create-user"
          name="Create user"
          component={CreateUser} />
        <Route
          exact
          path="/recover-account"
          name="Recover account"
          component={RecoverAccount} />
        <Route
          exact
          path="/recover-password"
          name="Recover password"
          component={RecoverPassword} />
        <ProtectedRoute
          path="/dashboard"
          name="Dashboard"
          pathToRedirect="/"
          component={Dashboard}
        />
      </Switch>
    </Router>
  );
}

export default App;
