import React from "react";
import { Form } from "semantic-ui-react";

const Occupation = ({ data, loading, error, value, onChange }) => {
  if (loading) return (
    <Form.Dropdown
      fluid
      label="Ocupación"
      required
      loading
      selection
      placeholder="Ocupación *"
      options={[]} />
  );

  if (error) return (
    <Form.Dropdown
      fluid
      label="Ocupación"
      required
      error
      selection
      placeholder="Ocupación *"
      options={[]} />
  );
  const { ocupacion } = data;

  return (
    <Form.Dropdown
      fluid
      label="Ocupación"
      required
      search
      value={value}
      selection
      onChange={onChange}
      placeholder="Ocupación *"
      options={ocupacion} />
  );
}

export default Occupation;