import React from "react";
import { Form } from "semantic-ui-react";

const Stratum = ({
  data,
  loading,
  error,
  value,
  onChange
}) => {
  if (loading) return (
    <Form.Dropdown
      fluid
      label="Estrato"
      required
      loading
      selection
      placeholder="Estrato *"
      options={[]} />
  );

  if (error) return (
    <Form.Dropdown
      fluid
      label="Estrato"
      required
      error
      selection
      placeholder="Estrato *"
      options={[]} />
  );
  const { estrato } = data;

  return (
    <Form.Dropdown
      fluid
      label="Estrato"
      required
      search
      value={value}
      selection
      onChange={onChange}
      placeholder="Estrato *"
      options={estrato} />
  );
}

export default Stratum;