import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTablePagination";
import DocumentResponse from "../../../Components/DocumentResponse";
import Swal from "sweetalert2";

const Requests = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const { loading, error, data } = useQuery(REQUESTS, {
    variables: { pagina: { limit: pageLimit, number: currentPage } },
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingProfile,
    error: errorProfile,
    data: dataProfile,
    refetch,
  } = useQuery(PROFILE);

  const [mutation] = useMutation(UPDATE_PROFILE);

  const showAlert = () => {
    if (
      dataProfile &&
      dataProfile?.me?.fechaTerminosYCondicionesNuevos == null
    ) {
      Swal.fire({
        icon: "warning",
        html: "Hemos actualizado nuestra Política y tratamientos de datos. Al continuar utilizando nuestros servicios, acepta estos términos actualizados que puede consultar en nuestro <a href='https://multidiomas.edu.co/images/manual_politicas.pdf' target='_blank'>Manual de Políticas y Procedimientos  Habeas Data</a>",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#B0193F",
        allowEnterKey: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const input = {};
          input.fechaTerminosYCondicionesNuevos = new Date();
          await mutation({ variables: { input } });
          window.location.reload();
        }
      });
    }
  };

  showAlert();

  const columns = [
    {
      header: "Nombre",
      accessor: "documento.enunciado",
    },
    {
      header: "Fecha",
      accessor: "fechaCreacion",
      render: (date) => moment(date).format("DD-MM-YYYY h:mm:ss A"),
    },
    {
      header: "Fecha de actualización",
      accessor: "fechaActualizacion",
      render: (date) => moment(date).format("DD-MM-YYYY h:mm:ss A"),
    },
    {
      header: "Estado solicitud",
      accessor: "estadoSolicitud.valorParametro",
    },
    {
      header: "Mensaje de solicitud",
      accessor: "",
      render: (object) => {
        return (
          <>
            {["13","14","113"].includes(object.estadoSolicitud.id) && (
              <Button
                onClick={() =>
                  Swal.fire({
                    icon: "info",
                    title: "Mensaje de solicitud",
                    html: object.estadoSolicitud.id == "113" ? object.mensajeSolicitudEnTramite : object.estadoSolicitud.id == "13" ? object.mensajeSolicitudAprobada : object.mensajeSolicitudRechazada,
                    confirmButtonText: "Aceptar",
                  })
                }
              >
                Mostrar mensaje
              </Button>
            )}
          </>
        );
      },
    },
    {
      header: "Detalle",
      accessor: "id",
      render: (id) => (
        <DocumentResponse
          trigger={<Icon name="arrow alternate circle right" link />}
          id={id}
        />
      ),
    },
  ];

  return (
    <CustomTable
      error={error}
      loading={loading}
      columns={columns}
      data={data?.multiSolicitudesSesion.items}
      currentPage={currentPage}
      onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
      pageLimit={pageLimit}
      setPageLimit={setPageLimit}
      setCurrentPage={setCurrentPage}
      totalPages={data?.multiSolicitudesSesion?.totalPages || 0}
    />
  );
};

const REQUESTS = gql`
  query requests($pagina: PageInput!) {
    multiSolicitudesSesion(
      filtro: { estado: ACTIVO }
      orden: { direccion: DESC, campo: FECHA_CREACION }
      pagina: $pagina
    ) {
      items {
        id
        fechaCreacion
        fechaActualizacion
        documento {
          id
          enunciado
        }
        estadoSolicitud {
          id
          valorParametro
        }
        mensajeSolicitudRechazada
        mensajeSolicitudAprobada
        mensajeSolicitudEnTramite
        estado
      }
      count
      totalPages
      page {
        number
        limit
      }
    }
  }
`;

const UPDATE_PROFILE = gql`
  mutation updateProfile($input: ActualizarMultiUsuarioInput!) {
    actualizarPerfil(input: $input) {
      id
    }
  }
`;

const PROFILE = gql`
  query profile {
    me {
      fechaTerminosYCondicionesNuevos
    }
  }
`;
export default Requests;
