import React, { useState } from "react";
import { Dropdown, Form, Input, Radio } from "semantic-ui-react";

const MultiInput = ({
  id,
  type = 'Texto',
  name,
  num,
  onChange,
  onChangeFile,
  required,
  label,
  options = [],
  placeholder
}) => {

  const [check, setCheck] = useState(false);

  const handleOnCheck = (e, data) => {
    setCheck(data?.value);
    onChange(e, data);
  }

  if (type === "Archivo") return (
    <div style={{ marginTop: 30 }}>
      <Form.Field
        enunciado={label}
        pos={num}
        idcampo={id}
        tipo={type}
        name={name}
        onChange={onChangeFile}
        required={required}
        label={`${num + 1}. ${label}`}
        type="file"
        control={Input}
        placeholder={placeholder}
      />
      <p style={{ fontSize: 12, marginBottom: 20 }}>Límite de número de archivos:1   Límite de tamaño del archivo individual: 10MB</p>
    </div>
  );

  if (type === "Calendario") return (
    <Form.Input
      type="date"
      idcampo={id}
      pos={num}
      tipo={type}
      enunciado={label}
      name={name}
      label={`${num + 1}. ${label}`}
      onChange={onChange}
      placeholder={placeholder}
      required={required} />
  );

  if (type === "Radio") return (
    <Form.Field style={{ marginTop: 30 }} required={required}>
      <label>{`${num + 1}. ${label}`}</label>
      {options.map((option, key) => (
        <Form.Field key={key}>
          <Radio
            tipo={type}
            enunciado={label}
            pos={num}
            idcampo={id}
            label={option?.enunciado}
            name={name}
            value={option?.enunciado}
            checked={check === option?.enunciado}
            onChange={handleOnCheck} />
        </Form.Field>
      ))}
    </Form.Field>
  );

  if (type === "Opcion") {
    let data = options.map(option => ({
      text: option?.enunciado,
      value: option?.enunciado,
      key: option?.id
    }));

    return (
      <Form.Dropdown
        idcampo={id}
        tipo={type}
        pos={num}
        enunciado={label}
        name={name}
        selection
        onChange={onChange}
        control={Dropdown}
        required={required}
        label={`${num + 1}. ${label}`}
        placeholder={placeholder}
        options={data}
      />
    );
  }

  if (type === "Opcion" || type === "Opcion - Aforo") {
    let data = options.filter(option => option.pagos<option.valor);
    data = data.map(option => ({
      text: option?.enunciado,
      value: option?.enunciado,
      key: option?.id
    }));

    return (
      <Form.Dropdown
        idcampo={id}
        tipo={type}
        pos={num}
        enunciado={label}
        name={name}
        selection
        onChange={onChange}
        control={Dropdown}
        required={required}
        label={`${num + 1}. ${label}`}
        placeholder={placeholder}
        options={data}
      />
    );
  }

  return (
    <Form.Field
      idcampo={id}
      pos={num}
      tipo={type}
      enunciado={label}
      name={name}
      onChange={onChange}
      required={required}
      label={`${num + 1}. ${label}`}
      control={Input}
      placeholder={placeholder}
    />
  );
}

export default MultiInput;