import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getData } from "../../Utils";

const Protect = (props) => {
  const { component: Component, pathToRedirect, ...rest } = props;
  if (getData("tokenUserMulti")) return <Component {...rest} />;

  return (
    <Redirect
      to={{
        pathname: pathToRedirect,
        state: { from: rest.location },
      }}
    />
  );
};

const ProtectedRoute = ({ component, pathToRedirect, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Protect
        component={component}
        pathToRedirect={pathToRedirect}
        {...props}
      />
    )}
  />
);

export default ProtectedRoute;