import { gql, useQuery } from "@apollo/client";
import React from "react";
import InputSearch from "../../Components/InputSearch";
const Origin = ({ value, onChange, label, placeholder, error }) => {
  const { loading, error: errorData, data } = useQuery(ORIGIN);

  return (
    <InputSearch
      loading={loading}
      value={value}
      error={errorData}
      inputError={error}
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      data={data?.multiValorParametros} />
  );
}

const ORIGIN = gql`
  query origin {
    multiValorParametros(filtro: { estado: ACTIVO, idParametro: 1 }) {
      value: id
      text: valorParametro
      key: id
    }
  }`;

export default Origin;