import React from "react";
import { Form } from "semantic-ui-react";

const LevelEducation = ({ data, loading, error, value, onChange }) => {
  if (loading) return (
    <Form.Dropdown
      fluid
      label="Nivel de formación"
      required
      loading
      selection
      placeholder="Nivel de formación *"
      options={[]} />
  );

  if (error) return (
    <Form.Dropdown
      fluid
      label="Nivel de formación"
      required
      error
      selection
      placeholder="Nivel de formación *"
      options={[]} />
  );
  const { nivelFormacion } = data;

  return (
    <Form.Dropdown
      fluid
      label="Nivel de formación"
      required
      search
      value={value}
      selection
      onChange={onChange}
      placeholder="Nivel de formación *"
      options={nivelFormacion} />
  );
}

export default LevelEducation;