import React from "react";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { Modal } from "semantic-ui-react";

const CameraDialog = (props) => {
    const { setPhoto, setReadOnlyPhoto, open, setOpen } = props;
    const closeDialog = () => {
        setOpen(false);
    };

    const urltoFile = (url, filename, mimeType) => {
        return fetch(url)
            .then((res) => res.arrayBuffer())
            .then((buf) => new File([buf], filename, { type: mimeType }));
    }

    const onTakePhotoHandler = async (e) => {
        let file = await urltoFile(e, "foto.jpeg", "image/jpeg");
        setPhoto(file)
        setReadOnlyPhoto(true);
        setOpen(false);
    };

    return (
        <>
            <Modal onClose={closeDialog} open={open}>
                <Modal.Content>
                    <Camera
                        onTakePhoto={onTakePhotoHandler}
                        idealFacingMode={FACING_MODES.USER}
                        isImageMirror={false}
                    />
                </Modal.Content>
            </Modal>
        </>
    );
};

export default CameraDialog;