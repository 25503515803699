import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Image, Message, Popup, Tab } from "semantic-ui-react";
import Swal from "sweetalert2";
import WARNING from "../../../assets/warning.png"
import { checkDateInvalid, chekcStartDate } from "../../../Utils/Constants";

const Documents = ({ documents = [] }) => {
  const handleLocation = document => {
    if (chekcStartDate(document?.fechaInicio)) {
      Swal.fire({
        title: document?.mensajeFechaInicio,
        icon: 'warning'
      });
    } else {
      if (checkDateInvalid(document?.fechaCaducidad)) {
        Swal.fire({
          title: document?.mensajeCaducidad,
          icon: 'warning'
        });
      }
    }
    if (document?.cupoLimitado) {
      Swal.fire({
        title: "No hay más cupos para este formulario",
        icon: 'warning'
      });
    }
  };

  const gotTo = document => {
    if (chekcStartDate(document?.fechaInicio)) return "home";
    if (checkDateInvalid(document?.fechaCaducidad)) return "home";
    if (document?.cupoLimitado) return "home";
    return `document/${document?.id}`;
  }

  if (!documents.length) return (
    <Tab.Pane style={{ minHeight: "100vh", border: 'none' }}>
      <Message>No hay documentos disponibles.</Message>
    </Tab.Pane>
  );

  return (
    <Tab.Pane style={{ minHeight: "100vh", border: 'none' }}>
      <Card.Group stackable>
        {documents.map((document, key) => (
          <Card key={key}>
            <Card.Content>
              {checkDateInvalid(document?.fechaCaducidad) && (
                <Popup
                  content={document?.mensajeCaducidad}
                  trigger={<Image
                    floated='right'
                    size='mini'
                    src={WARNING}
                  />}
                />)}
              <Card.Header>{document?.nombre}</Card.Header>
              <Card.Description>
                <h5 style={{ marginTop: 5 }}>Fecha de Inicio: {moment(document?.fechaInicio).format("DD/MM/YYYY")}</h5>
                <h5 style={{ marginTop: 5, color: checkDateInvalid(document?.fechaCaducidad) ? 'red' : null }}>Fecha de vencimiento: {moment(document?.fechaCaducidad).format("DD/MM/YYYY")}</h5>
                <h5 style={{ marginTop: 5 }}>Tipo de documento: {document?.tipoDocumento?.valorParametro}</h5>
              </Card.Description>
            </Card.Content>
            <Card.Content textAlign="center" extra>
              <Link to={() => gotTo(document)} onClick={() => handleLocation(document)}>
                <Button style={{ backgroundColor: '#B0193F', color: '#fff' }} content="Seleccionar" />
              </Link>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </Tab.Pane>
  );
}

export default Documents;